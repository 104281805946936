
import React from 'react';
import { isBrowser } from '../../utils/zeotap';
import LogLink from '../components/LogLink';

class MockTcf extends React.Component {
  state = isBrowser() && window.mockTCFConfig ? window.mockTCFConfig : {}

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value})
  }

  setSessionStoredString = (s) =>{
    if(isBrowser() && window.sessionStorage){
        const key = "mockTcfString";
        return window.sessionStorage.setItem(key,s); 
    }
    return null;
  }

  getSessionStoredString(key){
    if(isBrowser() && window.sessionStorage){
        return window.sessionStorage.getItem(key) ? (window.sessionStorage.getItem(key)) : ''
    }
    return null;
  }

  addConfigDetails = async () => {
    const { gdprApplies='',loadTcfApi='',tcloaded='',consents='',consentString='',legitimateInterests='' } = this.state
    const configTCFStr = `${gdprApplies};${loadTcfApi};${tcloaded};${consents};${legitimateInterests};${consentString}`
    this.setSessionStoredString(configTCFStr)
    if(isBrowser())
    window.location.reload()
  }

  render() {
    const {
        gdprApplies,loadTcfApi,tcloaded,consents,consentString,legitimateInterests
      } = this.state
      return (
        <>
        <LogLink />
        <div className="pt-10">
          <h3>Mock TCF Config Details</h3>
          <div className="flex flex-1 justify-center">
            <div className="w-full max-w-144">
              <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="gdprApplies">
                    GDPR Applies
                  </label>
                  <input aria-label="gdpr"
                    onChange={this.onChange} value={gdprApplies} name="gdprApplies"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="gdprApplies" type="text" placeholder="Enter true or false" />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="loadTcfApi">
                    Load TCF API
                  </label>
                  <input aria-label="loadtcf"
                    onChange={this.onChange} value={loadTcfApi} name="loadTcfApi"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="loadTcfApi" type="text" placeholder="Enter true or false" />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tcloaded">
                    TCF Loaded
                  </label>
                  <input aria-label="tcloaded"
                    onChange={this.onChange} value={tcloaded} name="tcloaded"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="tcloaded" type="text" placeholder="Enter true or false" />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="consents">
                   Consents
                  </label>
                  <input aria-label="consents"
                    onChange={this.onChange} value={consents} name="consents"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="consents" type="text" placeholder="Enter consents string separated by ','" />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="consents">
                   Legitimate Interests
                  </label>
                  <input aria-label="legitimateInterests"
                    onChange={this.onChange} value={legitimateInterests} name="legitimateInterests"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="consents" type="text" placeholder="Enter consents string separated by ','" />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="consentString">
                   Consent String
                  </label>
                  <input aria-label="consentstring"
                    onChange={this.onChange} value={consentString} name="consentString"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="consentString" type="text" placeholder="Enter Consent String" />
                </div>
                <div className="flex items-center justify-between">
                  <button onClick={this.addConfigDetails} className="bg-secondary hover:bg-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                    Update TCF Config
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        </>
      )
    }
}

export default MockTcf
